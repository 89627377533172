<template>
    <div class="dashboard">
        <div class="container-fluid">
            <div class="content-area">
                <div class="dashboard-content">
                    <div class="dashboard-header clearfix">
                        <div class="row">
                            <div class="col-md-6 col-sm-12">
                                <h4>الطلبات</h4>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <div class="breadcrumb-nav">
                                    <ul>
                                        <li>
                                            <router-link to="/my"
                                                >الرئيسية</router-link
                                            >
                                        </li>
                                        <li class="active">جميع الطلبات</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="column col-lg-12">
                            <div class="my-invoices">
                                <div class="inner-container clearfix">
                                    <div class="invoice-box">
                                        <div class="table-responsive">
                                            <table
                                                id="bookings-table"
                                                class="table table-striped table-bordered table-hover"
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>عنوان العقار</th>
                                                        <th>إسم العميل</th>
                                                        <th>تاريخ الطلب</th>
                                                        <th>الدخول</th>
                                                        <th>الخروج</th>
                                                        <th>حفلة</th>
                                                        <th>الإجمالى</th>
                                                        <th>التقييم</th>
                                                        <th>الحالة</th>
                                                    </tr>
                                                </thead>
                                                <tbody></tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import "@/plugins/datatables";
import $ from "jquery";

export default {
    name: "my.all-bookings",
    data() {
        return {
            table: null,
        };
    },
    mounted() {
        this.prepareTable();
    },
    methods: {
        prepareTable() {
            this.table = $("#bookings-table")
                .DataTable({
                    ajax: {
                        url:
                            process.env.VUE_APP_API_BASE_URL +
                            "/my/all-bookings",
                        crossDomain: true,
                        xhrFields: { withCredentials: true },
                        beforeSend: () => {
                            this.$loading.show();
                        },
                    },
                    serverSide: true,
                    stateSave: true,
                    columns: [
                        { data: "id" },
                        { data: "title" },
                        { data: "client.name" },
                        { data: "created_at" },
                        {
                            name: "from_day",
                            data: null,
                            render: (row) => {
                                return (
                                    row.from_day +
                                    " " +
                                    (row.from_period +
                                        " " +
                                        (row.from_period == 1
                                            ? "فترة أولى"
                                            : row.from_period == 2
                                            ? "فترة ثانية"
                                            : "فترة ثالثة"))
                                );
                            },
                        },
                        {
                            name: "to_day",
                            data: null,
                            render: (row) => {
                                return (
                                    row.to_day +
                                    " " +
                                    (row.to_period +
                                        " " +
                                        (row.to_period == 1
                                            ? "فترة أولى"
                                            : row.to_period == 2
                                            ? "فترة ثانية"
                                            : "فترة ثالثة"))
                                );
                            },
                        },
                        {
                            data: "party",
                            render: (party) => {
                                switch (party) {
                                    case "birthday":
                                        return "عيد ميلاد";
                                        break;
                                    case "wedding":
                                        return "زفاف";
                                        break;
                                    case "other":
                                        return "أخرى";
                                        break;
                                    default:
                                        return "لا يوجد";
                                        break;
                                }
                            },
                        },
                        { data: "total" },
                        {
                            data: "property.average_overall_rating",
                            render: (rating) => {
                                return (
                                    `<div class="rating text-info">
                                    <span class="` +
                                    (rating >= 1
                                        ? "la la-star"
                                        : "la la-star-o") +
                                    `"></span>
                                    <span class="` +
                                    (rating >= 2
                                        ? "la la-star"
                                        : "la la-star-o") +
                                    `"></span>
                                    <span class="` +
                                    (rating >= 3
                                        ? "la la-star"
                                        : "la la-star-o") +
                                    `"></span>
                                    <span class="` +
                                    (rating >= 4
                                        ? "la la-star"
                                        : "la la-star-o") +
                                    `" ></span>
                                        <span class="` +
                                    (rating >= 5
                                        ? "la la-star"
                                        : "la la-star-o") +
                                    `" ></span>
                                    </div>`
                                );
                            },
                        },
                        {
                            data: "status",
                            render: (status) => {
                                switch (status) {
                                    case "pending":
                                        return "بانتظار تأكيد الدفع";
                                        break;
                                    case "authorized":
                                        return "تم تأكيد الدفع";
                                        break;
                                    case "accepted":
                                        return "تم الموافقة من المعلن وسحب المبلغ";
                                        break;
                                    case "request_cancel":
                                        return "تم طلب الإلغاء من الإدارة";
                                        break;
                                    case "canceled":
                                        return "تم الموافقة على الإلغاء";
                                        break;
                                    case "finished":
                                        return "إنتهى";
                                        break;
                                    case "rated":
                                        return "تم التقييم";
                                        break;
                                }
                            },
                        },
                    ],
                })
                .on("draw", () => {
                    this.$loading.hide(0);
                });
        },
    },
};
</script>

<style>
@import "https://cdn.datatables.net/1.10.22/css/jquery.dataTables.min.css";
</style>